import http from "../utils/request";
// 获取财务信息
export function getBillList(data) {
  return http({
    url: "/admin/finance/overview",
    data: data,
    method: "post",
  });
}

// 获取汇总信息
export function aggBill(data) {
  return http({
    url: "/admin/finance/agg",
    data: data,
    method: "post",
  });
}

// 获取对账明细
export function detailBill(data) {
  return http({
    url: "/admin/finance/detail",
    data: data,
    method: "post",
  });
}
