<template>
  <div class="bill">
    <div style="margin: 5px auto"></div>
    <div class="pageContent">
      <div class="billSearchForm_box">
        <el-form :inline="true" size="small" :model="billSearchForm">
          <el-form-item label="店鋪">
            <el-select
              v-model="billSearchForm.shop_id"
              clearable
              filterable
              placeholder="所有店鋪"
            >
              <!--              <el-option value="">全部店鋪</el-option>-->
              <el-option
                v-for="item in shopList"
                :key="item.shop_id"
                :value="item.shop_id"
                :label="item.shop_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="時間範圍">
            <el-select
              v-model="billSearchForm.type"
              style="width: 150px"
              @change="typeSelect"
            >
              <el-option label="日賬單" value="month"></el-option>
              <el-option label="月賬單" value="year"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-if="billSearchForm.type == 'year'"
              v-model="billSearchForm.date"
              style="width: 150px"
            >
              <el-option
                v-for="item in yearList"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <el-date-picker
              style="width: 150px"
              v-if="billSearchForm.type == 'month'"
              type="month"
              format="yyyy-MM"
              value-format="yyyy-MM"
              v-model="billSearchForm.date"
              placeholder="選擇月份"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="雲值守">
                        <el-select v-model="billSearchForm.watch" style="width: 100px">
                            <el-option label="不限" :value="3"></el-option>
                            <el-option label="是" :value="1"></el-option>
                            <el-option label="否" :value="0"></el-option>
                        </el-select>
                    </el-form-item> -->
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="searchCalendar"
              >搜索
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="census_box">
        <div>
          <span
            ><img
              width="21"
              height="23"
              :src="require('@/assets/image/money1.png')"
            />
            當{{ txt }}累計收款：</span
          >
          <span class="num_box">{{ sale_price }}</span>
        </div>
        <div>
          <span
            ><img
              width="21"
              height="23"
              :src="require('@/assets/image/money2.png')"
            />
            當{{ txt }}累計毛利潤：</span
          >
          <span class="num_box">{{ sale_profit }}</span>
        </div>
        <!-- <div>
          <span
            ><img
              width="21"
              height="23"
              :src="require('@/assets/image/money3.png')"
            />
            固定月服務費：</span
          >
          <span class="num_box">{{ service_fee }}</span>
        </div> -->
      </div>
      <el-row>
        <el-col class="calendarLeft" :span="22">
          <a-calendar
            v-loading="calendarLoading"
            :mode="modeType"
            :header-render="headerRender"
            :value="calendarValue"
            :disabledDate="disabledDate"
            @select="calendarSelect"
          >
            <ul slot="dateCellRender" slot-scope="value" class="events">
              <li v-for="item in getListData(value)" :key="item.content">
                <a-badge :status="item.type" :text="item.content" />
              </li>
            </ul>
            <template slot="monthCellRender" slot-scope="value">
              <div
                v-for="monthItem in getMonthData(value)"
                :key="monthItem.content"
                class="notes-month"
              >
                <a-badge :status="monthItem.type" :text="monthItem.content" />
              </div>
            </template>
          </a-calendar>
        </el-col>
        <el-col :span="8">
          <div class="detail_box">
            <div class="detailTime_box">{{ selectDate }}</div>
            <div class="detailText_box">
              <span>對賬明細</span>
            </div>
            <div v-if="order_num > 0">
              <el-row style="padding-right: 13px; margin-bottom: 10px">
                <el-col :span="7"> 類型</el-col>
                <el-col :span="6"> 訂單量</el-col>
                <el-col :span="6"> 金額</el-col>
              </el-row>
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    <el-row style="width: 100%">
                      <el-col :span="7">收款</el-col>
                      <el-col :span="6">{{ order_num }}</el-col>
                      <el-col :span="6">{{ order_price }}</el-col>
                      <el-col :span="5"
                        ><span class="checkDetail">分類明細</span>
                      </el-col>
                    </el-row>
                  </template>
                  <div>
                    <el-row
                      style="
                        width: 100%;
                        padding-right: 13px;
                        line-height: 40px;
                      "
                      v-for="(item, index) in aggList"
                      :key="index"
                    >
                      <el-col :span="7">{{ item.name }}</el-col>
                      <el-col :span="6">{{ item.count }}</el-col>
                      <el-col :span="6">{{ item.price }}</el-col>
                      <el-col :span="5"
                        ><span
                          class="checkDetail"
                          @click="checkDetail(item.tag)"
                          >查看詳情</span
                        ></el-col
                      >
                    </el-row>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <el-empty v-else description="暫無信息"></el-empty>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      :visible.sync="detailVisible"
      :title="
        '對賬詳情' + selectDate + ' 類型：已付款 ' + '支付渠道：' + payChannel
      "
      width="1000px"
    >
      <el-table
        :header-cell-style="{
          'text-align': 'center',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :data="tableData"
        style="width: 100%"
        v-loading="tableLoading"
      >
        <el-table-column label="訂單號" align="center" prop="order_no">
        </el-table-column>
        <el-table-column label="訂單時間" prop="create_time"></el-table-column>
        <el-table-column label="商品金額" prop="order_price"></el-table-column>
        <el-table-column label="店鋪" prop="shop_name"></el-table-column>
        <el-table-column label="實收金額" prop="pay_price">
          <!-- <template slot-scope="scope">
            <span :class="scope.row.status == '00' ? 'green' : 'red'">{{
              scope.row.status == "00" ? "消費" : "利潤"
            }}</span>
          </template> -->
        </el-table-column>
        <el-table-column label="利润" prop="profit"></el-table-column>
        <!-- <el-table-column label="收款金額" prop="transAmt"></el-table-column> -->
      </el-table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 50]"
        :total="total"
        :page-size="pageSize"
      ></el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import { getBillList, aggBill, detailBill } from "@/api/finance";
import { listShop } from "@/api/shop";
import moment from "moment";

export default {
  data() {
    return {
      calendarLoading: false,
      shopList: "",
      yearList: "",
      billSearchForm: {
        shop_id: "",
        type: "month",
        date: "",
        watch: 3,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      modeType: "month",
      billList: [],
      calendarValue: "",
      disabledDate: (current) => {
        return current > moment().endOf("day");
      },
      selectDate: "",
      payChannel: "",
      // 詳情
      detailVisible: false,
      tableLoading: false,
      tableData: [],
      pageSize: 50,
      page: 1,
      total: 0,
      sale_price: 0,
      sale_profit: 0,
      service_fee: 0,
      txt: "月",
      aggList: [],
      order_num: null,
      order_price: null,
    };
  },
  created() {
    this.yearList = [];
    const year = new Date().getFullYear();
    for (var i = 2018; i <= year; i++) {
      this.yearList.push(i);
    }
    this.billSearchForm.date = this.time.formatMonth(new Date());
    this.getAggBill();
  },
  mounted() {
    this.getShopList();
    this.getBillData();
  },
  methods: {
    headerRender() {},
    getShopList() {
      listShop({
        page: 1,
        pageSize: 999999,
        shop_name: "",
        shop_id: "",
      }).then((res) => {
        this.shopList = res.data.list;
      });
    },
    getBillData() {
      this.calendarLoading = true;
      getBillList({
        shop_id: this.billSearchForm.shop_id,
        type: this.billSearchForm.type,
        date: this.billSearchForm.date,
        watch: this.billSearchForm.watch,
      }).then((res) => {
        this.calendarLoading = false;
        this.billList = res.data.list;
        (this.sale_price = res.data.sale_price),
          (this.sale_profit = res.data.sale_profit),
          (this.service_fee = res.data.service_fee),
          (this.calendarValue = moment(
            this.billList[this.billList.length - 1].today
          ));
        this.selectDate = this.billList[this.billList.length - 1].today;
        if (this.billSearchForm.type == "month") {
          this.txt = "月";
        } else {
          this.txt = "年";
        }
      });
    },
    getListData(value) {
      let listData;
      for (var i in this.billList) {
        if (value.format("YYYY-MM-DD") == this.billList[i].today) {
          listData = [
            {
              type: "success",
              content: "收款：" + this.billList[i].sale_price + "",
            },
            {
              type: "success",
              content: "利潤：" + this.billList[i].profit + "",
            },
          ];
        }
      }
      return listData || [];
    },

    getMonthData(value) {
      let listData;
      for (var i in this.billList) {
        if (value.format("YYYY-MM") == this.billList[i].today.substring(0, 7)) {
          listData = [
            {
              type: "success",
              content: "收款：" + this.billList[i].sale_price + "",
            },
            {
              type: "success",
              content: "利潤：" + this.billList[i].profit + "",
            },
          ];
        }
      }
      return listData || [];
    },
    // 搜索
    searchCalendar() {
      this.modeType = this.billSearchForm.type;
      this.getBillData();
    },
    // 修改時間範圍
    typeSelect() {
      if (this.billSearchForm.type == "year") {
        this.billSearchForm.date = this.yearList[this.yearList.length - 1];
      } else {
        this.billSearchForm.date = this.time.formatMonth(new Date());
      }
    },
    // 選擇日期
    calendarSelect(value) {
      this.selectDate = value.format("YYYY-MM-DD");
      this.getAggBill();
    },
    // 查看詳情
    checkDetail(channel) {
      //   if (channel == "title") {
      //     this.payChannel = "全部";
      //   } else if (channel == "wx") {
      //     this.payChannel = "微信";
      //   } else if (channel == "alipay") {
      //     this.payChannel = "支付寶";
      //   }
      this.payChannel = channel;
      this.detailVisible = true;
      this.getDetailBill();
      //   this.tableData = [
      //     {
      //       channel: "bjyl",
      //       checkDate: "20221001",
      //       closed: "00",
      //       finOrderType: "05",
      //       orderId: "221001BJD077705D00421068",
      //       orderTime: 1664629896000,
      //       orderType: "00",
      //       payChannel: "",
      //       payType: "wechat_payscore_bjyl",
      //       realAmt: "14.62",
      //       status: "00",
      //       storeId: "BJD077",
      //       transAmt: "14.62",
      //       transId: "221001BJD077705T00834192",
      //       transTime: 1664629901000,
      //     },
      //   ];
    },
    // 获取汇总信息
    async getAggBill() {
      if (this.selectDate == "") {
        this.selectDate = moment().format("YYYY-MM-DD");
      }
      try {
        const { data } = await aggBill({
          start_time: this.selectDate,
          shop_id: this.billSearchForm.shop_id,
          type: this.billSearchForm.type,
        });
        this.aggList = data.list;
        this.order_num = data.count;
        this.order_price = data.total;
      } catch (e) {
        console.log(e);
      }
    },
    // 获取详情
    async getDetailBill() {
      try {
        const { data } = await detailBill({
          start_time: this.selectDate,
          shop_id: this.billSearchForm.shop_id,
          type: this.billSearchForm.type,
          tag: this.payChannel,
          page: this.page,
          pageSize: this.pageSize,
        });
        this.total = data.count;
        this.tableData = data.list;
      } catch (e) {
        console.log(e);
      }
    },
    // 分頁
    handleSizeChange() {},
    handleCurrentChange() {},
  },
};
</script>
<style lang="scss">
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 10px;
}
.ant-badge > .ant-badge-status-text {
  font-size: 10px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

.billSearchForm_box {
  background: #fff;
  padding: 10px;
  margin-bottom: 16px;

  .el-form-item {
    margin-bottom: 10px;
  }
}

.calendarLeft {
  background: #fff;
}

.bill .el-row {
  display: flex;
}

.detail_box {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  background: #fff;
  padding: 10px;

  .detailTime_box {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }

  .detailText_box {
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 20px;

    > span {
      display: inline-block;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-top: 1px solid #ebeef5;
      border-left: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-bottom: -1px;
      background: #fff;
      color: #1890ff;
    }
  }

  .el-collapse {
    border-top: none;
  }

  ::v-deep .el-collapse-item__header {
    justify-content: space-between;
    background: #fafafa;
  }

  ::v-deep .el-collapse-item__arrow {
    margin: 0 8px 0 0;
  }

  ::v-deep .el-collapse-item__content {
    padding: 0;
  }

  .checkDetail {
    color: #1890ff;
    cursor: pointer;
  }
}

.census_box {
  width: 100%;
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;

  > div {
    width: 30%;
  }

  .num_box {
    font-size: 20px;
    font-weight: bold;
  }
}
li > span > span {
  font-size: 10px;
}
</style>
